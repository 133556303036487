<template>
  <div>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
    <form action="#" v-if="vmSwapBillingFormData && Object.keys(vmSwapBillingFormData).length > 0">
      <div class="form-row">
        <!-- Billing Email id (OLD) -->
        <div class="col-md-12 mb-3">
          <label for="validationslot_duration">{{ cvBilledEmail }}</label>&nbsp;
          <input :readonly="isOldEmailEditable()" v-model="vmSwapBillingFormData.old_email" type="text" class="form-control" required/>
        </div><!-- Billing Email id (OLD) -->

        <!-- Billing Email id (NEW) -->
        <div class="col-md-12 mb-3">
          <label for="validationslot_duration">{{ cvUserEmailLabel }}</label>&nbsp;
          <input v-model="vmSwapBillingFormData.new_email" type="text" class="form-control" required/>
        </div><!-- Billing Email id (NEW) -->
        <b-button variant="primary" @click="swapBillingDetails">
          Swap Billings
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { User } from "../FackApi/api/user"

export default {
  name: "BillingSwap",
  data () {
    return {
      cvUserNameLabel: "User Name",
      cvUserEmailLabel: "Billing Email Id (NEW)",
      cvBilledEmail: "Billing Email Id (OLD)",
      vmSwapBillingFormData: {},
      showToast: false,
      toastMsg: "",
      toastVariant: "default",
      toastTitle: "Billing Swap"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    this.vmSwapBillingFormData = this.propUserData
  },
  props: {
    propUserData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * swapBillingDetails
     */
    async swapBillingDetails () {
      try {
        const userBillSwapResp = await User.UserBillDetailsSwap(this, this.vmSwapBillingFormData)
        if (!userBillSwapResp.resp_status) {
          this.toastVariant = "danger"
          this.toastMsg = userBillSwapResp.resp_msg
          this.showToast = true
          return
        }
        this.toastVariant = "success"
        this.toastMsg = userBillSwapResp.resp_msg
        this.showToast = true

        this.$emit("emitCloseBillingSwapModal")
      }
      catch (err) {
        console.error("Exception in swapBillingDetails and err: ", err)
      }
    },
    /**
     * isOldEmailEditable
     */
    isOldEmailEditable () {
      if (this.userData.user_role == "USERROLE11111") {
        return false
      }
      return true
    }
  }
}
</script>

<style>

</style>
