/*eslint-disable */
import request from "../../../Utils/curl"

/**
 * FakeApi analytics user
 */
let analyticsUser = {
  async analytics_user (context, whereFilter = null) {
    try {
      let postData = new FormData()
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "analytics_user", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at analytics_user and Exception:", err.message)
    }
  },
  
  /**
   * FakeApi analytics user counts for school dashboard
   */  
  async user_list_school_count (context, whereFilter = null) {
    try {
      let postData = new FormData()
      if (whereFilter) {
        postData.append("filter", JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_list_school_count", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at user_list_school_count and Exception:", err.message)
    }
  }
}

export {
  analyticsUser
}
