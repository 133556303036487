<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}: {{userRoles[whereFilter.user_role]}} <small>({{whereFilter.dateRange.startDate | dateFormatDDMMYYYYSlash}} - {{whereFilter.dateRange.endDate | dateFormatDDMMYYYYSlash}})
              </small>
            </h4><br>
          </template>
          <template>
            <div class="row p-0">
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card text-white bg-primary iq-mb-3">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div>
                          Total<br>Users
                      </div>
                      <h5 class="text-white">{{allCount}}
                      </h5>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card iq-mb-3 text-white" style="background-color:var(--cyan)">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div>
                       Completed<br>Regs
                    </div>
                      <h5 class="text-white">{{parseInt(profileCompleted)}} ({{ Math.floor((parseInt(profileCompleted))/allCount*100,0) }}%)
                      </h5>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card iq-mb-3 text-white bg-danger">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div>
                        Incomplete <br>Regs.
                    </div>
                      <h5 class="text-white">{{parseInt(allCount)-parseInt(profileCompleted)}} ({{ Math.floor((parseInt(allCount)-parseInt(profileCompleted))/allCount*100,0) }}%)
                      </h5>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card text-white iq-mb-3 bg-warning">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div>
                          Total<br>{{userRoles[whereFilter.user_role]}}
                      </div>
                      <h5 class="text-white">{{userCount}}
                      </h5>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card iq-mb-3 text-white" style="background-color:var(--indigo)">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div v-if="whereFilter.user_role == 'USERROLE11114'">
                        {{ whereFilter.Class }} Class <br> Students
                      </div>
                      <div v-else>
                        Registration<br />Complete
                      </div>
                      <h5 class="text-white">{{userProfileCompleted}} ({{ Math.ceil(userProfileCompleted/allCount*100,0) }}%)
                      </h5>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card iq-mb-3 text-whit bg-primary">
                  <div class="card-body">
                    <h5 class="card-title text-white" style="text-align:center">
                      <div v-if="whereFilter.user_role == 'USERROLE11114'">
                        {{ whereFilter.Class }} Class <br>
                        Gide+ Users
                      </div>
                      <div v-else>
                        Gide+<br />Users
                      </div>
                      <h5 class="text-white">{{paidCount}} ({{ Math.ceil(paidCount/allCount*100,0) }}%)
                      </h5>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template>
            <b-row class="w-100 mb-3">
              <b-col class="col-12 col-sm-4 col-md-3 col-lg-3 mb-2">
              <!-- Frontend Search -->
                <!-- <b-form-input class="search_box"
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input> -->
              <!-- Frontend Search -->

              <!-- Backend Search -->
              <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText" />
              <!-- Backend Search -->
              </b-col>
              <b-col class="col-12 col-sm-4 col-md-3 col-lg-2 mb-2">
                <select v-model="whereFilter.user_role" class="form-control" @change="setUserRoleFilter">
                  <option  v-for="(user_role, index) of userRoles" :key="(index+1)" :value="index" >
                    {{user_role}}
                  </option>
                </select>
              </b-col>
              <!-- User status (active/deleted) -->
              <b-col class="col-12 col-sm-4 col-md-2 col-lg-1 mb-2">
                <select v-model="whereFilter.user_status" class="form-control" @change="setUserStatus">
                  <option :value="9">
                    Active
                  </option>
                  <option :value="10" >
                    Deleted
                  </option>
                  <option :value="11" >
                    Blocked
                  </option>
                </select>
              </b-col><!-- User status (active/deleted) -->
              <b-col class="col-12 col-sm-4 col-md-2 col-lg-1 mb-2">
                <select v-if="whereFilter.user_role == 'USERROLE11114'" v-model="whereFilter.Class" class="form-control" @change="setUserRoleFilter">
                    <option v-for="(Class, index) of Class" :key="index+'OrgType'">{{ Class }}</option>
                    {{Class}}
                </select>
              </b-col>
              <b-col class="col-12 col-sm-8 col-md-2 col-lg-2 mb-2">
                <!-- Frontend csv download -->
                <!-- <VueJsonToCsv :json-data="userObjList" csv-title="userDocument" v-if="userObjList && userObjList.length">
                  <b-btn variant="primary" class="Download_button"> CSV
                  </b-btn>
                </VueJsonToCsv>Frontend csv download -->

                <!-- Backend csv download -->
                <b-btn variant="primary" class="Download_button" @click="backendCsvDownload"> CSV</b-btn>
                <!-- Backend csv download -->
              </b-col>
              <b-col class="w-100 col-12 col-sm-12 col-md-12 col-lg-3 mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100" v-model="whereFilter.dateRange" @update="userListDateWise()"></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="userObjList && userObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="userObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage">
                  <template v-slot:cell(user_name)="data">
                    <p v-html="data.item.user_name" style="word-wrap: break-word" class="mb-0" @click="goToUserProfile(data.item)"></p>
                    <span>
                      <i>Id:<span v-html="data.item.user_id"></span></i>
                      <i class="fa-solid fa-copy pointer pl-2" @click="doCopy(data.item.user_id)"></i><br>
                      <small>Status: <b><span class="primary-color">{{ getUserStatus(data.item.user_status) }}</span></b></small><br>
                    </span>
                    <span class="font-size-20">
                      <i class="fa fa-eye" title="View User profile" @click="goToUserProfile(data.item)"></i>
                      <i class="ml-3 fa fa fa-ban" title="Block User" @click="showUserBlockDialog(data.item)" v-if="data.item.user_status == 9"></i>
                      <i class="ml-3 fa fa-undo" title="Restore account" @click="showRestoreUserAccountDialog(data.item)" v-if="data.item.user_status != 9"></i>
                      <i class="ml-3 fa fa-user-circle-o" title="Edit User Details" @click="showEditUserDetails(data.item)"></i>
                      <i class="ml-3 fa fa fa-credit-card-alt" title="Billing History" @click="showUserBillingHistory(data.item)"></i>
                      <i class="ml-3 fa fa-exchange" title="Swap Billing" @click="billingSwap(data.item)"></i>
                      <i class="ml-3 fa fa-inr" title="Add Subscription" @click="showAddSubscriberModal(data.item)"></i>
                      <i class="ml-3 fa fa-money" title="User Payment Transaction" @click="showPaymentList(data.item)"></i>
                      <i class="ml-3 fa fa-repeat" title="User Subscription" @click="showSubscriptionList(data.item)"></i>
                      <i class="ml-3 fa fa-map-signs" title="GPaths" @click="showGpathsList(data.item)"></i>
                    </span>
                  </template>
                  <template v-slot:cell(user_email)="data">
                    <p v-html="data.item.user_email" style="word-wrap: break-word"></p>
                  </template>
                  <template v-slot:cell(user_id)="data">
                    <p v-html="data.item.user_id" style="word-wrap: break-word" @click="goToUserProfile(data.item)"></p>
                  </template>
                  <template v-slot:cell(user_mobile)="data">
                    <p v-html="data.item.user_mobile" style="word-wrap: break-word"></p>
                  </template>
                  <template v-slot:cell(class)="data">
                    <p style="word-wrap: break-word"> {{ data.item.class }}</p>
                  </template>
                  <template v-slot:cell(org_name)="data">
                    <p v-html="data.item.org_name" style="word-wrap: break-word"></p>
                  </template>
                  <template v-slot:cell(user_role_name)="data">
                    <p>
                      {{data.item.user_role_name}}
                    <b-button title="Edit User Role" class="btn actionIcon font-size-20 mr-1 mb-1" size="sm" @click="userEdit('MODAL', data.item)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                    </p>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row v-if="showLoadMoreBtn">
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- Block User Confirmation Modal -->
    <b-modal v-model="showModelUserBlock" size="sm" title="User Block" ok-title="Block" cancel-title="Cancel">
      <p v-if="blockObj && blockObj.user_status> 0 && blockObj.user_status<11">
        {{cvBlockMessage}}
      </p>
      <p v-if="blockObj && blockObj.user_status === 11">
        {{cvUnblockMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUserBlock = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button v-if="blockObj && blockObj.user_status > 0 && blockObj.user_status<11" size="sm" variant="primary" @click="userBlock()">
          {{cvbtnModalBlockOk}}
        </b-button>
        <b-button v-if="blockObj && blockObj.user_status ===11" size="sm" variant="primary" @click="userBlock()">
          {{cvbtnModalUnblockOk}}
        </b-button>
      </template>
    </b-modal><!-- Block User Confirmation Modal -->

     <!-- Add Subscriber Modal -->
    <b-modal v-model="isAddSubscriberModalOpen" size="lg" title="Add Subscriber" ok-title="Add" cancel-title="Cancel">
      <ValidationObserver ref="subForm">
      <form action="#">
        <div class="form-row">
          <!-- User Email -->
          <div class="col-md-12 mb-3">
            <label for="validationslot_duration">{{ cvUserEmailLabel }}</label>&nbsp;
            <input v-model="vmAddSubscriberFormData.user_email" type="text" class="form-control" required/>
          </div><!-- User Email -->

          <!-- Subscription Plan Dropdown -->
          <div class="col-md-12 mb-3">
            <label for="validationslot_duration">{{ cvSubscriptionPlan }}</label>&nbsp;
            <select v-model="vmAddSubscriberFormData.subs_plan_id" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="item in planIdList" :value="item.subs_plan_id" :key="item.subs_plan_id">{{ item.subs_plan_name }}&nbsp;-&nbsp;{{item.subs_plan_price}}</option>
            </select>
          </div><!-- Subscription Plan Dropdown -->

          <!-- User Description -->
          <div class="col-md-12 mb-3">
              <ValidationProvider
                name="Description"
                rules="required"
                v-slot="{ errors }">
              <label for="validationslot_duration">{{ cvDescriptionLabel }}</label> <label class="primary-color font-size-16">*</label> &nbsp;
            <textarea v-model="vmAddSubscriberFormData.pay_params" type="text" class="form-control textarea" required>
            </textarea>
              <span class="text-danger"> {{ errors[0] }} </span>
              </ValidationProvider>
          </div><!-- User Description -->
        </div>
      </form>
      </ValidationObserver>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showAddSubscriberModal">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="addSubscriber()">
          {{cvbtnAddSubscriber}}
        </b-button>
      </template>
    </b-modal><!-- Add Subscriber Modal -->

    <!-- Restore User Account Confirmation Modal -->
    <b-modal v-model="showModelRestoreUserAccount" size="sm" title="User Account Restore" ok-title="Block" cancel-title="Cancel">
      <p>
        {{cvRestoreUserAccountMsg}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelRestoreUserAccount = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="restoreUserAccount()">
          {{cvbtnRestoreAccount}}
        </b-button>
      </template>
    </b-modal><!-- Block User Confirmation Modal -->

    <!-- Edit User Details -->
    <b-modal v-model="showEditUserDetailsModal" size="lg" title="Edit User Details" ok-title="Block" cancel-title="Cancel">
      <form action="#" v-if="vmUserEditFormData && Object.keys(vmUserEditFormData).length > 0">
        <div class="form-row">
          <!-- User Name -->
          <div class="col-md-12 mb-3">
            <label for="validationslot_duration">{{ cvUserNameLabel }}</label>&nbsp;
            <input v-model="vmUserEditFormData.user_name" type="text" class="form-control" required/>
          </div><!-- User Name -->

          <!-- User Email -->
          <div class="col-md-12 mb-3">
            <label for="validationslot_duration">{{ cvUserEmailLabel }}</label>&nbsp;
            <input v-model="vmUserEditFormData.user_email" type="text" class="form-control" required/>
          </div><!-- User Email -->

          <!-- User Mobile -->
          <div class="col-md-12 mb-3">
            <label for="validationslot_duration">{{ cvUserMobileLabel }}</label>&nbsp;
            <input v-model="vmUserEditFormData.user_mobile" type="text" class="form-control" required/>
          </div><!-- User Mobile -->
        </div>
      </form>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showEditUserDetails">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="userDataEdit()">
          {{cvbtnEditUserDetails}}
        </b-button>
      </template>
    </b-modal><!-- Block User Confirmation Modal -->

    <!-- Swap User Billing Data -->
    <b-modal
    size="lg"
    :title="billingSwapModalTitle"
    v-model="openBillingSwapModal"
    ok-title="Swap Billing"
    :hide-footer="true"
    >
      <BillingSwap @emitCloseBillingSwapModal="emitCloseBillingSwapModal" :propUserData="propUserData" />
    </b-modal><!-- Swap User Billing Data -->

    <b-modal
      v-model="showModelUserEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <UserEdit :propOpenedInModal="true" :propUserObj="userEditObj" @emitCloseUserEditModal="closeUserEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="userEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeUserEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showModelUserDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelUserDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="userDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style scoped>
.dateRange {
  float: right;
  }
  .iq-card .iq-card-header{
    padding: 0px!important;
  }
  .user-card{
    font-size: 30px !important;
  }
  .textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
<script>
import { User } from "../../../FackApi/api/user.js"
import UserEdit from "./UserEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { analyticsUser } from "../../../FackApi/api/analytics/analytics_user.js"
import userRoles from "../../../FackApi/json/UserRoles.json"
import Class from "../../../FackApi/json/Class.json"
import BillingSwap from "../../../components/BillingSwap.vue"
import { PayTransactionss } from "../../../FackApi/api/PayTransactions.js"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan.js"
import { socialvue } from "../../../config/pluginInit.js"
import { ValidationProvider } from "vee-validate"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "UserList",
  components: {
    UserEdit,
    BillingSwap,
    ValidationProvider
  },
  data () {
    return {
      apiName: "user_list",
      cvModuleFile: "user",
      JsonData: [],
      cvModulePrefix: "USER",
      cvCardTitle: "Users",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvbtnModalBlockOk: "Block",
      cvbtnModalUnblockOk: "Unblock",
      cvDelMessage: "Are you sure?",
      cvBlockMessage: "Are you sure you want to block this user?",
      cvbtnRestoreAccount: "Restore Account",
      cvbtnEditUserDetails: "Edit User Details",
      showModelRestoreUserAccount: false,
      showEditUserDetailsModal: false,
      cvUserNameLabel: "User Name",
      cvUserEmailLabel: "User Email",
      cvDescriptionLabel: "Add Notes: Requestor Name, Purpose etc",
      cvUserMobileLabel: "User Mobile",
      cvRestoreUserAccountMsg: "Are you sure you want to restore this user's account?",
      cvUnblockMessage: "Are you sure you want to unblock this user?",
      cvEditModalHeader: "User Role Edit",
      cvAddModalHeader: "Add User",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User List",
      showModelUserBlock: false,
      showModelUserEdit: false,
      showModelUserDelete: false,
      sortBy: "name",
      userRoles: userRoles,
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "user role", key: "user_role_name", class: "text-left align-text-top", sortable: true },
        { label: "user name", key: "user_name", class: "text-left align-text-top pointer w-350px", sortable: true },
        // { label: "User Id ", key: "user_id", class: "text-left align-text-top pointer ", sortable: true },
        { label: "source", key: "source", class: "text-left align-text-top pointer", sortable: true },
        { label: "user email", key: "user_email", class: "text-left align-text-top", sortable: true },
        { label: "user mobile", key: "user_mobile", class: "text-left align-text-top", sortable: true },
        { label: "Is Gide+", key: "subs_plan_id", class: "text-left align-text-top", sortable: true },
        { label: "Subs status", key: "status", class: "text-left align-text-top", sortable: true },
        { label: "user country", key: "user_country", class: "text-left align-text-top", sortable: true },
        { label: "user city", key: "user_city", class: "text-left align-text-top", sortable: true },
        { label: "Class Name", key: "class", class: "text-left align-text-top", sortable: true },
        { label: "School name", key: "org_name", class: "text-left align-text-top", sortable: true },
        // { label: "School City", key: "org_city", class: "text-left align-text-top", sortable: true },
        // { label: "School State", key: "org_state", class: "text-left align-text-top", sortable: true },
        // { label: "School Country", key: "org_country", class: "text-left align-text-top", sortable: true },
        { label: "user fcm push token", key: "user_fcm_push_token", class: "text-left align-text-top", sortable: true },
        { label: "user is logged in", key: "user_is_logged_in", class: "text-left align-text-top", sortable: true },
        { label: "user is profile complete", key: "user_is_profile_complete", class: "text-left align-text-top", sortable: true },
        { label: "User Email OTP", key: "user_email_verified", class: "text-left align-text-top", sortable: true },
        { label: "user timezone", key: "user_timezone", class: "text-left align-text-top", sortable: true },
        // { label: "user status", key: "user_status", class: "text-left align-text-top", sortable: true },
        // { label: "user role status", key: "user_role_status", class: "text-left align-text-top", sortable: true },
        { label: "user tnc", key: "user_tnc", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true },
        { label: "Last Login", key: "modified_on", class: "text-left align-text-top", sortable: true },
        { label: "User Forgot Password OTP", key: "user_otp", class: "text-left align-text-top", sortable: true },
        { label: "User Mobile Verified", key: "user_mobile_verified", class: "text-left align-text-top", sortable: true }
      ],
      vmUserEditFormData: null,
      userObjList: [],
      userEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      blockObj: null,
      restoreObj: null,
      userCount: null,
      paidCount: null,
      allCount: null,
      userProfileCompleted: null,
      profileCompleted: null,
      vmUserBlock: Object.assign({}, this.initUserBlockData()),
      Class: Class,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // in Last 7 Days
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        user_role: "USERROLE11114",
        Class: 12,
        user_status: 9,
        search_param: ""
      },
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: false,
      openBillingSwapModal: false,
      propUserData: null,
      billingSwapModalTitle: "Swap Billing Data",

      planIdList: [],
      cvSubscriptionPlan: "Subscription Plan",
      cvbtnAddSubscriber: "Add",
      isAddSubscriberModalOpen: false,
      vmAddSubscriberFormData: Object.assign({}, this.initFormData())
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    await this.getFiltersFromCache()

    this.userList()
    this.analytics_user()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    userRoleFilter () {
      return this.$store.getters["UserRole/selectedUserRoleState"]
    }
  },
  methods: {
    /**
     * Get User Status
     */
    getUserStatus (status) {
      switch (status) {
        case 10:
          return "BLOCKED"
        case 9:
        default:
          return "ACTIVE"
      }
    },
    /**
     * showAddSubscriberModal
     */
    async showAddSubscriberModal (userData) {
      await this.getPlanNames()
      this.vmAddSubscriberFormData.user_email = userData.user_email
      this.isAddSubscriberModalOpen = !this.isAddSubscriberModalOpen
    },
    /**
     * addSubscriber
     */
    async addSubscriber () {
      let valid = await this.$refs.subForm.validate()
      if (!valid) {
        return
      }
      try {
        const subscriberAddResp = await PayTransactionss.payUserAddSubscriber(this, this.vmAddSubscriberFormData)
        if (!subscriberAddResp.resp_status) {
          this.toastMsg = subscriberAddResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        this.vmAddSubscriberFormData = Object.assign({}, this.initFormData())
        this.toastMsg = subscriberAddResp.resp_msg
        this.toastVariant = "success"
        this.showToast = true
        this.isAddSubscriberModalOpen = false
      }
      catch (err) {
        console.error("Exception in addSubscriber and err: ", err)
      }
    },
    /**
     * getPlanNames
     */
    async getPlanNames () {
      try {
        const subsPlanListResp = await SubscriptionPlans.subscriptionPlanList(this)
        if (subsPlanListResp.resp_status) {
          this.planIdList = subsPlanListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Error in getPlanNames ", err)
      }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        "user_email": "",
        "subs_plan_id": ""
      }
    },
    /**
     * get data from store cache by this method
    */
    async getFiltersFromCache () {
      await this.getDateFilter()
      await this.getUserRoleFilter()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.userList(true)
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * emitCloseBillingSwapModal
     */
    emitCloseBillingSwapModal () {
      this.openBillingSwapModal = false
    },
    /**
     * get Subject Module Filter
     */
    async getUserRoleFilter () {
      let storeDate = await this.userRoleFilter
      if (storeDate) {
        this.whereFilter.user_role = storeDate
      }
    },
    /**
     * setUserRoleFilter
     */
    async setUserRoleFilter () {
      this.$store.commit("UserRole/addUserRole", this.whereFilter.user_role)
      this.userList()
      this.analytics_user()
    },
    /**
     * setUserStatus
     */
    async setUserStatus () {
      this.userList()
      this.analytics_user()
    },
    /**
     * calling user_analytics and userlist function on date wise and storing value in store
     */
    async userListDateWise () {
      this.userList()
      this.analytics_user()
      await this.setDateFilter()
    },
    /**
     *
     * Redirect to User Profile
     */
    goToUserProfile (user) {
      try {
        window.open(`/user/${user.user_id}`)
      }
      catch (err) {
        console.error("Exception occurred at UserList() and Exception:", err.message)
      }
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      await this.userList()
      await this.analytics_user()
    },
    /**
     * showPaymentList
     */
    showPaymentList (user) {
      window.open(`/payTransactions_list/${user.user_id}`)
    },
    /**
     * showSubscriptionList
     */
    showSubscriptionList (user) {
      window.open(`/subscription_list/${user.user_id}`)
    },
    /**
     * showGpats
     */
    showGpathsList (user) {
      window.open(`/gpath_session_list/${user.user_id}`)
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      console.log("Msg Copy")

      // this.snackbar = true;
      // this.err_msg = 'Copied';
    },
    /**
     *  initUserBlockData
     */
    initUserBlockData () {
      return {
        user_id: "",
        curr_user_id: "",
        user_status: ""
      }
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.userList(false, true)
    },
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * userList
     */
    async userList (loadViaLoadMore = false, downloadCsv = false) {
      this.analytics_user()
      this.showLoadMoreBtn = true
      try {
        if (this.$route.params.sch_id) {
          this.whereFilter.user_role = "USERROLE11114"
        }
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.downloadCsv = downloadCsv

        let userListResp = await User.userList(this, this.whereFilter)
        if (userListResp.resp_status) {
          if (downloadCsv) {
            window.open(userListResp.resp_csv_file_url)
            return
          }

          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.userObjList = [ ...userListResp.resp_data.data ]
          }
          else {
            this.userObjList = [ ...this.userObjList, ...userListResp.resp_data.data ]
          }
          this.totalRows = this.userObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = userListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
        this.currentBatchNo = Math.ceil(this.userObjList.length / this.dataPerPage)
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    },
    /**
     * userEdit
     */
    userEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/user_edit/" + this.userEditObj.user_id)
        }
        else {
          this.userEditObj = item
          this.showModelUserEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userEdit() and Exception:", err.message)
      }
    },
    /**
     * showUserDeleteDialog
     */
    showUserDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUserDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUserDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * showUserBlockDialog
     */
    showUserBlockDialog (item) {
      try {
        this.blockObj = item
        this.showModelUserBlock = true
      }
      catch (err) {
        console.error("Exception occurred at showUserBlockDialog() and Exception:", err.message)
      }
    },
    /**
     * showRestoreUserAccount
     */
    showRestoreUserAccountDialog (item) {
      try {
        this.restoreObj = item
        this.showModelRestoreUserAccount = true
      }
      catch (err) {
        console.error("Exception occurred at showRestoreUserAccount() and Exception:", err.message)
      }
    },
    /**
     * showEditUserDetails
     */
    showEditUserDetails (userData) {
      this.vmUserEditFormData = userData
      this.showEditUserDetailsModal = !this.showEditUserDetailsModal
    },
    /**
     * showUserBillingHistory
     */
    showUserBillingHistory (userData) {
      window.open(`/my_billing_history/${userData.user_id}`, "_blank")
    },
    /**
     * billingSwap
     */
    billingSwap (userData) {
      this.propUserData = {
        old_email: userData.user_email,
        old_user_id: userData.user_id,
        old_user_name: userData.user_name
      }
      this.openBillingSwapModal = !this.openBillingSwapModal
    },
    /**
     * userDataEdit
     */
    async userDataEdit () {
      try {
        const userEditResp = await User.userEdit(this, this.vmUserEditFormData)
        if (userEditResp.resp_status) {
          this.showEditUserDetailsModal = false
        }
      }
      catch (err) {
        console.error("Exception in userDataEdit and Error: ", err)
      }
    },
    /**
     * restoreUserAccount
     */
    async restoreUserAccount () {
      try {
        let userRestoreResp = await User.userRestoreAccount(this, this.restoreObj.user_id, this.restoreObj.deleted)
        await ApiResponse.responseMessageDisplay(this, userRestoreResp)
        if (userRestoreResp && !userRestoreResp.resp_status) {
          this.showModelRestoreUserAccount = false
          return false
        }
        else {
          let index = this.userObjList.indexOf(this.restoreObj)
          let updatedUserData = { ...this.restoreObj }
          updatedUserData.user_status = 9
          this.$set(this.userObjList, index, updatedUserData)
        }
      }
      catch (err) {
        console.error("Exception occurred at restoreUserAccount() and Exception:", err.message)
      }
      finally {
        this.showModelRestoreUserAccount = false
        this.blockObj = null
      }
    },
    /**
     * userDelete
     */
    async userDelete () {
      try {
        let userDelResp = await User.userDelete(this, this.delObj.user_id)
        await ApiResponse.responseMessageDisplay(this, userDelResp)
        if (userDelResp && !userDelResp.resp_status) {
          this.showModelUserDelete = false
          return false
        }
        let index = this.userObjList.indexOf(this.delObj)
        this.userObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUserDelete = false
      }
      catch (err) {
        console.error("Exception occurred at userDelete() and Exception:", err.message)
      }
    },
    /**
     * userBlock
     */
    async userBlock () {
      try {
        if (this.blockObj.user_status && this.blockObj.user_status > 0) {
          this.vmUserBlock.user_status = 10 // block  user
          this.vmUserBlock.deleted = 1
        }

        this.vmUserBlock.user_id = this.blockObj.user_id
        this.vmUserBlock.curr_user_id = this.userData.user_id

        let userBlockResp = await User.userBlock(this, this.vmUserBlock)
        await ApiResponse.responseMessageDisplay(this, userBlockResp)
        if (userBlockResp && !userBlockResp.resp_status) {
          return false
        }
        else {
          let index = this.userObjList.indexOf(this.blockObj)
          let updatedUserData = { ...this.blockObj }
          updatedUserData.user_status = this.vmUserBlock.user_status
          this.$set(this.userObjList, index, updatedUserData)
        }
      }
      catch (err) {
        console.error("Exception occurred at userBlock() and Exception:", err.message)
      }
      finally {
        this.showModelUserBlock = false
        this.blockObj = null
        this.vmUserBlock = Object.assign({}, this.initUserBlockData())
      }
    },
    closeUserEditModal () {
      try {
        this.showModelUserEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUserEditModal() and Exception:", err.message)
      }
    },
    /**
     * analytics_user
     */
    async analytics_user () {
      try {
        let userAnalyticsList = await analyticsUser.analytics_user(this, this.whereFilter)
        if (userAnalyticsList.resp_status) {
          this.userCount = userAnalyticsList.resp_data.data[0].totalCount
          this.allCount = userAnalyticsList.resp_data.data[0].allCount
          this.userProfileCompleted = userAnalyticsList.resp_data.data[0].totalProfileCompleteRoleWise
          this.profileCompleted = userAnalyticsList.resp_data.data[0].totalProfileComplete
          this.paidCount = userAnalyticsList.resp_data.data[0].pa
        }
        else {
          this.toastMsg = userAnalyticsList.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at user_quick_profile() and Exception:", err.message)
      }
    }
  }
}
</script>
